<template>
  <div class="miserver-table">
    <!-- Table for miservers -->
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Endpoint</th>
          <th>API</th>
          <th>Available</th>
        </tr>
      </thead>
      <tbody v-if="ajaxCompleted">
        <tr
          v-for="miserver in miservers"
          @click="handleRowClick(miserver._id)"
          :data-id="miserver._id"
          :key="miserver._id"
        >
          <td>
            {{ miserver.name }}
          </td>
          <td>
            {{ miserver.endpoint }}
          </td>
          <td>
            {{ miserver.apiversion }}
          </td>
          <td>
            <span v-if="miserver.available"><i class="uil uil-check" /></span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" class="loader">
            <img :src="loadingImage" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Utils from '@/utils';

export default {
  name: 'miserverTable',
  props: ['savedAt'],
  data() {
    return {
      ajaxCompleted: false,
      miservers: [],
    };
  },
  watch: {
    savedAt() {
      this.getmiservers();
    },
  },
  methods: {
    async getmiservers() {
      const response = await Utils.fetch('/api/v1/miservers', {}, this).then((res) => res.json());
      if (response.success) {
        this.ajaxCompleted = true;
        this.miservers = response.miservers;
      }
    },
    async handleRowClick(id) {
      this.$emit('rowClick', id);
      this.$router.replace(`/miservers/${id}`);
    },
  },
  mounted() {
    this.getmiservers();
    this.$root.$on('reloadTable', () => {
      this.getmiservers();
    });
  },
};
</script>

<style lang="scss" scoped>
.miserver-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.miserver-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }
}

.bullet {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 100%;

  &.blue {
    background: rgb(88, 195, 220);
  }

  &.green {
    background: #4dbd74;
  }

  &.red {
    background: #f86c6b;
  }
}
</style>
