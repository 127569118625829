<template>
  <div>
    <h1 class="title">Link new MagicINFO server</h1>
    <grid>
      <grid-col size="8">
        <card title="Enter Properties">
          <div class="property">
            <div class="title">Name</div>
            <input type="text" placeholder="Name" class="input-text" v-model="miserver.name" />
          </div>
          <div class="property">
            <div class="title">Endpoint</div>
            <input
              type="text"
              placeholder="Endpoint"
              class="input-text"
              v-model="miserver.endpoint"
              @change="resetValidation"
            />
            <div class="example">
              Example: <code>https://srv.domain.com/MagicInfo/</code> or
              <code>http://srv.domain.com:7001/MagicInfo/</code>
            </div>
          </div>

          <div class="property">
            <div class="title">Username</div>
            <input
              type="text"
              placeholder="Username"
              class="input-text"
              v-model="miserver.username"
              @change="resetValidation"
            />
          </div>

          <div class="property">
            <div class="title">Password</div>
            <input
              type="text"
              placeholder="Password"
              class="input-text"
              v-model="miserver.password"
              @change="resetValidation"
            />
          </div>

          <TextProperty
            title="Version"
            :value="miserver.version"
            v-show="miserver.version !== ''"
          />

          <TextProperty
            title="API Version"
            :value="miserver.apiversion"
            v-show="miserver.version !== ''"
          />

          <TextProperty
            title="Number of displays"
            :value="miserver.displays.toString()"
            v-if="miserver.displays && miserver.displays !== ''"
          />

          <div class="property">
            <div class="title">Capture endpoint</div>
            <i
              v-if="miserver.capture"
              class="uil uil-check-circle green"
              :title="miserver.capture"
            />
            <i v-else class="uil uil-check-circle red" />
          </div>

          <div class="property">
            <div class="title">Thumbnail endpoint</div>
            <i
              v-if="miserver.thumbnail"
              class="uil uil-check-circle green"
              :title="miserver.thumbnail"
            />
            <i v-else class="uil uil-check-circle red" />
          </div>

          <div class="property">
            <div class="title">Validated</div>
            <i v-if="miserver.token" class="uil uil-check-circle green" :title="miserver.token" />
            <i v-else class="uil uil-check-circle red" />
          </div>

          <div style="height: 30px">
            <span v-if="loading" class="loader">
              <img :src="loadingImage" />
            </span>
            <base-button
              v-else-if="miserver.token && miserver.username && miserver.password"
              v-on:click="onSave"
              class="left"
              isSmall
              type="CREATE"
              iconType="PLUS"
              title="Create server"
            />
            <base-button v-else isSmall title="Check credentials" v-on:click="onValidate" />
            <base-button
              isSmall
              type="THEMED"
              iconType="CANCEL"
              v-on:click="onCancel"
              title="Cancel"
            />
          </div>
        </card>
      </grid-col>
    </grid>
  </div>
</template>
<script>
import Utils from '@/utils';
import BaseComponent from '../Base.vue';
import TextProperty from '../Property/TextProperty.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'createmiserver',
  extends: BaseComponent,
  components: {
    TextProperty,
    Card,
    Grid,
    GridCol,
    BaseButton,
  },
  props: [],
  data() {
    return {
      ajaxCompleted: false,
      loading: false,
      miserver: {
        name: '',
        endpoint: '',
        username: '',
        password: '',
        token: '',
        version: '',
        apiversion: '',
        displays: '',
        capture: '',
        thumbnail: '',
      },
    };
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    fixupEndpointUrl() {
      // make sure the endpoint has no file after /MagicInfo/
      this.miserver.endpoint = this.miserver.endpoint.replace(/MagicInfo\/?.*?$/, 'MagicInfo/');
    },
    resetValidation() {
      // always append trailing slash
      // this.miserver.endpoint = this.miserver.endpoint.replace(/\/$/, '') + '/';
      this.fixupEndpointUrl();
      this.miserver.token = '';
      this.miserver.version = '';
      this.miserver.apiversion = '';
      this.miserver.hwunique = '';
      this.miserver.capture = '';
      this.miserver.thumbnail = '';
    },
    async onValidate() {
      this.loading = true;
      const response = await Utils.post(
        '/api/v1/miservers/validate',
        {
          endpoint: this.miserver.endpoint,
          username: this.miserver.username,
          password: this.miserver.password,
        },
        this,
      ).then((_res) => _res.json());

      this.loading = false;
      if (response.success) {
        this.miserver.token = response.token;
        this.miserver.version = response.version;
        this.miserver.apiversion = response.apiversion;
        this.miserver.hwunique = response.hwunique;
        this.miserver.displays = response.displays; // total number of displays on server
        this.miserver.capture = response.capture; // capture base url on server
        this.miserver.thumbnail = response.thumbnail; // thumbnail base url on server
      } else {
        this.$noty.warning(response.message);
      }
    },
    async onSave() {
      const res = await Utils.post(
        '/api/v1/miservers/create',
        {
          name: this.miserver.name,
          endpoint: this.miserver.endpoint,
          username: this.miserver.username,
          token: this.miserver.token,
          version: this.miserver.version,
          apiversion: this.miserver.apiversion,
          hwunique: this.miserver.hwunique,
          displays: this.miserver.displays, // to calculate checkInterval
          capture: this.miserver.capture,
          thumbnail: this.miserver.thumbnail,
        },
        this,
      ).then((_res) => _res.json());

      if (res.success) {
        this.$emit('saved');
        this.onCancel();
      } else {
        this.$noty.warning(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
code {
  color: darkorange;
}
div.property.max-w > div.value {
  display: inline-block;
  max-width: 600px;
  overflow: hidden;
  white-space: nowrap;
}
div.property div.example {
  margin-top: 10px;
}

.loader {
  padding: 20px 0;

  img {
    height: 30px;
  }
}
</style>
