<template>
  <div class="miserver-viewer" v-if="miserver !== null">
    <h1 class="title">Edit MagicINFO Server</h1>
    <grid>
      <grid-col size="8">
        <card title="Enter Properties">
          <div class="property">
            <div class="title">Name</div>
            <input type="text" placeholder="Name" class="input-text" v-model="miserver.name" />
          </div>
          <div class="property">
            <div class="title">Available</div>
            <input type="checkbox" placeholder="Available" v-model="miserver.available" />
          </div>
          <base-button iconType="PLUS" v-on:click="onSave" isSmall title="Save changes" />
          <base-button
            type="THEMED"
            :isSmall="true"
            class="right"
            title="Cancel"
            v-on:click="cancel"
          />
        </card>
      </grid-col>
    </grid>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>
<script>
import Utils from '@/utils';
import BaseComponent from '../Base.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'singlemiserver',
  extends: BaseComponent,
  components: {
    Card,
    Grid,
    GridCol,
    BaseButton,
  },
  props: ['miserverId'],
  watch: {
    miserverId(newVal) {
      this.getmiserver(newVal);
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      miserver: null,
    };
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    cancel() {
      this.$router.push(`/miservers/${this.miserverId}`);
    },
    async getmiserver(id) {
      const response = await Utils.fetch(`/api/v1/miservers/${id}`, {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.ajaxCompleted = true;
        this.miserver = response.miserver;
      }
    },
    async onSave() {
      const response = await Utils.fetch(
        `/api/v1/miservers/${this.miserver._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.miserver.name,
            available: this.miserver.available,
          }),
        },
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.$noty.success(
          `Successfully edited the MagicINFO server connection '${this.miserver.name}' from Wherever.`,
        );
        this.$emit('saved');
        this.$router.push('/miservers');
      } else {
        this.$noty.warning(response.message);
      }
    },
  },
  mounted() {
    this.getmiserver(this.miserverId);
  },
};
</script>
